import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import AzLayout from '../components/AzLayout'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SectionBlogList from '../components/SectionBlogList'
import SectionClearText from '../components/SectionClearText'
import SectionContacts from '../components/SectionContacts'
import SectionFooter from '../components/SectionFooter'
import SectionHero from '../components/SectionHero'
import SectionNewsletter from '../components/SectionNewsletter'
import SectionRecentPosts from '../components/SectionRecentPosts'
import SectionSocialMedia from '../components/SectionSocialMedia'
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WeiboShareButton,
    FacebookMessengerShareButton,
} from 'react-share'
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from 'react-share'
import {
    FacebookShareCount,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount,
} from 'react-share'

const themes = ['main', 'alt']

const PageTemplate = (props) => {
    const [admin, setAdmin] = useState(false)

    let isAdmin = admin

    const isEditing = props.data.allContentfulGlobalSettings.edges[0].node.editing
    if (isEditing) isAdmin = true

    const theme = themes[0]
    const { data, pageContext, location } = props
    const post = data.contentfulBlogPost

    if (!post) {
        return null
    }

    let width = ''
    let height = ''

    if (post.image && post.image.childImageSharp) {
        width = post.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0]
        height = String(Number(width) / post.image.childImageSharp.fluid.aspectRatio)
    }

    const date = post.date
    // 2018-08-20
    const datetime = date //format(date, 'yyyy-MM-dd')
    // 20 AUG 2018
    const displayDatetime = date //format(date, 'dd LLL yyyy')

    let header = {}
    let footer = {}
    if (props.data.allContentfulNavigation) {
        for (const edge of props.data.allContentfulNavigation.edges) {
            const block = edge.node
            if (block.links && block.links.length > 0 && block.links[0].node_locale === props.pageContext.language) {
                if (block.type === 'header') {
                    header = block
                }
                if (block.type === 'footer') {
                    footer = block
                }
            }
        }
    }

    header.data = { site: props.data.site, url: props.data.url }
    footer.data = { site: props.data.site, url: props.data.url }

    const content = []
    let index = 0
    if (props.data.allContentfulBlogFooter.edges[0].node.footerElements) {
        for (const block of props.data.allContentfulBlogFooter.edges[0].node.footerElements) {
            if (!block.internal || block.hidden) continue
            const type = block.internal.type
            switch (type) {
                case 'ContentfulAuthor':
                    content.push(<span>Missing author</span>)
                    break
                case 'ContentfulHero':
                    content.push(
                        <div>
                            <SectionHero isAdmin={isAdmin} key={index} {...{ ...props, section_id: 'hero' }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulClearText':
                    content.push(
                        <div>
                            <SectionClearText isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulRecentPosts':
                    content.push(
                        <div>
                            <SectionRecentPosts isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulNewsletter':
                    content.push(
                        <div>
                            <SectionNewsletter isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulContacts':
                    content.push(
                        <div>
                            <SectionContacts isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulSocialMedia':
                    content.push(
                        <div>
                            <SectionSocialMedia isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulFooter':
                    content.push(
                        <div>
                            <SectionFooter isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulNavigation':
                    if (block.type === 'footer') {
                        content.push(<Footer isAdmin={isAdmin} key={index} data={props.data} {...block} block={block} theme={theme} />)
                    }
                default:
                    break
            }
            index++
        }
    }

    const title = post.title
    const shareUrl = `https://spaghettishowdown.it/notizie-ed-eventi/${post.slug}`
    const shareButtonSize = 48

    return (
        <AzLayout {...props} siteClass="site-article-content">
            <Helmet>
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={post.subtitle} />
                <meta property="og:image" content={`https:${post.firstImage.file.url}`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://spaghettishowdown.it/notizie-ed-eventi/${post.slug}`} />
            </Helmet>

            <Header isAdmin={isAdmin} {...header} />
            <SectionHero isAdmin={isAdmin} {...{ ...props, section_id: 'hero' }} block={{ ...post, body: null }} theme={theme} />

            <div
                className="d-none d-xxxl-block"
                style={{
                    position: 'fixed',
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    width: 300,
                    backgroundColor: '#120C0C',
                    height: 'calc(100vh - 104px)',
                    top: 104,
                    zIndex: 9999,
                    borderLeft: '1px solid #800',
                    borderTop: '1px solid #800',
                }}
            >
                <SectionBlogList block={{ articles: props.data.allContentfulBlogPost.edges }} />
            </div>

            <div className="article-container" style={{ maxWidth: 1280, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#181818' }}>
                <header class="article__header px-3 pt-3">
                    {/* <h1 class="font-weight-bold ls-n1 lh-1 mb-2 mr-2 pr-5 position-relative">{post.title}</h1> */}

                    <h5>
                        <i>{post.subtitle}</i>
                    </h5>

                    {post.firstImage && <Img style={{ maxHeight: 720 }} alt={post.title} fluid={post.firstImage ? post.firstImage.fluid : null} />}

                    <div class="author-container mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-start justify-content-sm-between">
                        <div class="order-2 order-sm-1">
                            <span class="article__header__type-author">
                                Di&nbsp;
                                <a href="#">
                                    <strong>{post.author.name}</strong>
                                </a>
                            </span>
                            <span class="article__header__date">
                                &nbsp; — &nbsp;
                                <span id="_article_pub_date">{post.publishDate}</span>
                            </span>
                        </div>
                    </div>

                    <div class="wrap-save-social d-flex align-items-center justify-content-between my-3" style={{ overflowX: 'scroll' }}>
                        <FacebookShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button">
                            <FacebookIcon size={shareButtonSize} round />
                        </FacebookShareButton>

                        <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                            {(count) => count}
                        </FacebookShareCount>

                        <FacebookMessengerShareButton url={shareUrl} appId="521270401588372" className="Demo__some-network__share-button">
                            <FacebookMessengerIcon size={shareButtonSize} round />
                        </FacebookMessengerShareButton>

                        <TwitterShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <TwitterIcon size={shareButtonSize} round />
                        </TwitterShareButton>

                        <TelegramShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <TelegramIcon size={shareButtonSize} round />
                        </TelegramShareButton>

                        <WhatsappShareButton url={shareUrl} title={title} separator=":: " className="Demo__some-network__share-button">
                            <WhatsappIcon size={shareButtonSize} round />
                        </WhatsappShareButton>

                        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                            <LinkedinIcon size={shareButtonSize} round />
                        </LinkedinShareButton>

                        <PinterestShareButton
                            url={shareUrl}
                            // media={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <PinterestIcon size={shareButtonSize} round />
                        </PinterestShareButton>

                        <VKShareButton
                            url={shareUrl}
                            // image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <VKIcon size={shareButtonSize} round />
                        </VKShareButton>

                        <OKShareButton
                            url={shareUrl}
                            // image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <OKIcon size={shareButtonSize} round />
                        </OKShareButton>

                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                        >
                            <RedditIcon size={shareButtonSize} round />
                        </RedditShareButton>

                        <TumblrShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <TumblrIcon size={shareButtonSize} round />
                        </TumblrShareButton>

                        <LivejournalShareButton url={shareUrl} title={title} description={shareUrl} className="Demo__some-network__share-button">
                            <LivejournalIcon size={shareButtonSize} round />
                        </LivejournalShareButton>

                        <MailruShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <MailruIcon size={shareButtonSize} round />
                        </MailruShareButton>

                        <EmailShareButton url={shareUrl} subject={title} body="body" className="Demo__some-network__share-button">
                            <EmailIcon size={shareButtonSize} round />
                        </EmailShareButton>

                        <ViberShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <ViberIcon size={shareButtonSize} round />
                        </ViberShareButton>

                        <WorkplaceShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button">
                            <WorkplaceIcon size={shareButtonSize} round />
                        </WorkplaceShareButton>

                        <LineShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <LineIcon size={shareButtonSize} round />
                        </LineShareButton>

                        <WeiboShareButton
                            url={shareUrl}
                            title={title}
                            // image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <WeiboIcon size={shareButtonSize} round />
                        </WeiboShareButton>

                        <PocketShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <PocketIcon size={shareButtonSize} round />
                        </PocketShareButton>

                        <InstapaperShareButton url={shareUrl} title={title} className="Demo__some-network__share-button">
                            <InstapaperIcon size={shareButtonSize} round />
                        </InstapaperShareButton>

                        <HatenaShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                        >
                            <HatenaIcon size={shareButtonSize} round />
                        </HatenaShareButton>
                    </div>
                </header>
                <div className="article-content cell block-content">
                    {post.description && post.description.childMarkdownRemark.html !== '' && (
                        <div className="block-copy">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: post.description.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="article-content cell block-content">
                    {post.body && post.body.childMarkdownRemark.html !== '' && (
                        <div className="block-copy">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: post.body.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {content}
        </AzLayout>
    )
}

export const query = graphql`
    query ($slug: String) {
        allContentfulBlogPost(limit: 50, sort: { fields: publishDate, order: DESC }) {
            edges {
                node {
                    id
                    author {
                        email
                        company
                        contentful_id
                        createdAt
                        facebook
                        github
                        name
                        phone
                        title
                        twitter
                    }
                    body {
                        childMarkdownRemark {
                            id
                            html
                        }
                    }
                    firstImage {
                        fluid(maxHeight: 300, maxWidth: 800, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                        file {
                            fileName
                        }
                    }
                    slug
                    publishDate
                    shortName
                    title
                }
            }
        }
        logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
            childImageSharp {
                fixed {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        allContentfulGlobalSettings {
            edges {
                node {
                    contentful_id
                    id
                    editing
                }
            }
        }
        allContentfulBlogFooter {
            edges {
                node {
                    contentful_id
                    id
                    title
                    footerElements {
                        ... on ContentfulNavigation {
                            contentful_id
                            id
                            type
                            node_locale
                            logo {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            links {
                                id
                                title
                                node_locale
                                shortName
                                slug
                                navButtonType
                            }
                            title
                            internal {
                                type
                            }
                        }
                        ... on ContentfulSocialMedia {
                            contentful_id
                            id
                            title
                            facebook
                            linkedin
                            twitter
                            instagram
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                        }
                    }
                }
            }
        }
        allContentfulNavigation {
            edges {
                node {
                    contentful_id
                    id
                    type
                    node_locale
                    links {
                        id
                        title
                        node_locale
                        shortName
                        slug
                        navButtonType
                    }
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
        contentfulBlogPost(slug: { eq: $slug }) {
            contentful_id
            title
            shortName
            slug
            firstImage {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
                fixed(height: 332, width: 332) {
                    ...GatsbyContentfulFixed
                }
                file {
                    url
                }
            }
            separator {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
                fixed(height: 332, width: 332) {
                    ...GatsbyContentfulFixed
                }
            }
            separatorExtra {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
                fixed(height: 332, width: 332) {
                    ...GatsbyContentfulFixed
                }
            }
            heroImage {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
                fixed(height: 332, width: 332) {
                    ...GatsbyContentfulFixed
                }
            }
            subtitle
            description {
                id
                childMarkdownRemark {
                    id
                    html
                }
            }
            body {
                id
                childMarkdownRemark {
                    id
                    html
                }
            }
            author {
                contentful_id
                id
                name
                title
                company
                shortBio {
                    id
                    childMarkdownRemark {
                        id
                        html
                    }
                }
                email
                phone
                facebook
                twitter
                github
                image {
                    fluid(maxWidth: 1180, background: "rgb:000000") {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            publishDate(formatString: "MMMM Do, YYYY")
            tags
        }
        site {
            siteMetadata {
                title
                description
                base_font
                site_url
                footer {
                    content
                    has_nav
                    nav_links {
                        ... on SiteSiteMetadataFooterNav_links {
                            label
                            style
                            url
                        }
                    }
                    sections {
                        ... on SiteSiteMetadataFooterSections {
                            content
                            form_fields {
                                ... on SiteSiteMetadataFooterSectionsForm_fields {
                                    default_value
                                    input_type
                                    is_required
                                    label
                                    name
                                }
                            }
                            form_id
                            hide_labels
                            image
                            image_alt
                            image_url
                            nav_links {
                                ... on SiteSiteMetadataFooterSectionsNav_links {
                                    label
                                    style
                                    url
                                }
                            }
                            submit_label
                            title
                            type
                        }
                    }
                }
                header {
                    has_nav
                    logo_img
                    logo_img_alt
                    nav_links {
                        ... on SiteSiteMetadataHeaderNav_links {
                            label
                            style
                            url
                        }
                    }
                }
                palette
            }
        }
    }
`

export default PageTemplate
